import jquery from 'jquery';
import iziToast from "izitoast/dist/js/iziToast.min";

import accounting from "accounting-js";
window.$ = window.jQuery = jquery;
window.iziToast = iziToast;
window.accounting = accounting;
require('./bootstrap');
require('jquery-validation');
require('jquery-confirm');
require('datatables.net-bs4');
require('jquery.nicescroll');
require('select2');
require('bootstrap-daterangepicker');
require('./stisla');
require('./script');

$(window).on('load', function(){
    $('.spinner-container').fadeOut(300);
});
(function() {
    'use strict';
    window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);
})();

